import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"
import Helmet from 'react-helmet'

import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ErrorPage from '../pages/PropertyDetails404'

import TopBar from "../components/PropertyDetails/TopBar/TopBar";
import Banner from "../components/PropertyDetails/DetailsBanner/NewHomesBanner";
import ViewPage from "../components/PropertyDetails/ViewPage/ViewPageNewHomes";
import LoadExternalScript from "../components/utils/load-external-script";
// import Description from "../components/PropertyDetails/Description/Description";
// import RoomDetails from "../components/PropertyDetails/RoomDetails/RoomDetails";
// import Owner from "../components/PropertyDetails/Owner/Owner";
// import Payment from "../components/PropertyDetails/Payment/Payment";
// import Info from "../components/PropertyDetails/Info/Info";
// import SimilarProperty from "../components/PropertyDetails/Property/SimilarProperty";
import logoBlack from "../images/logo-dark.svg";
import { navigate } from "@reach/router"

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    newDevelopments(where:{id: $id}) {
      id
      development_id
      development_title 
      min_price
      max_price
      price_prefix
      images
      imagetransforms  
      latitude
      longitude 
      short_description
      long_description
      office_crm_id 
      sub_heading_2 
      developer_logo
      bedrooms
      banner_video
      floorplan 
      brochure
      telephone
    }
  }
`;

const locRatingScriptsJs = 'https://www.locrating.com/scripts/locratingIntegrationScripts.js';
const _firstMarkerImage = '.'
const renderMap = () => {
  if (typeof window !== 'undefined') {
    const _baseUrl = window.location.origin;
    if (typeof window.loadLocratingPlugin !== 'undefined') {
      window.loadLocratingPlugin({
        id: "property-location-map",
        lat: 51.30965,
        lng: 1.12385,
        // starttab: props.mapview ? props.mapview : 'location',
        type: "transport",
        icon: _firstMarkerImage,
        zoom: 13,
        mapstyle: 'voyager',
        hidestationswhenzoomedout: true,
      })
    }
  }

}


const PropertyDetailsTemplate = (props) => {

  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }, context: { clientName: "feed" }
  });
  // 
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
      </div>
    </section>
  );

  // console.log('property_details', property_details)
  // if (property_details.newDevelopments.length == 0) {
  //   navigate('/property/new-homes/for-sale/')
  // }
  return (
    <React.Fragment>
      {property_details && property_details.newDevelopments && property_details.newDevelopments.length > 0 ?
        <div className="wrapper property-details-wrapper new-homes">
          <Helmet
            bodyAttributes={{
              class: 'property-details-page-new-homes'
            }}
          />
          <Header />
          {property_details && property_details.newDevelopments && property_details.newDevelopments.length > 0 ? property_details.newDevelopments.map((data, i) => {
            const metaTitle = data.development_title

            const metaDesc = 'Book a viewing for this ' + data.development_title + ' at ' + data.price_range + ' with Wards. Contact us for more information about this property.'

            return (
              <>
                <SEO title={metaTitle} />
                <LoadExternalScript
                  src={locRatingScriptsJs}
                  // async="true"
                  defer="true"
                  loadScript={renderMap}
                // appendScriptTo="head"
                />
                <TopBar />
                <Banner propertyData={data} />
                <ViewPage propertyData={data} />
                {/* <SimilarProperty propertyData={data} /> */}
              </>
            )
          }) : ''}
          <Footer />
        </div> : <ErrorPage />}
    </React.Fragment>
  )
}

export default PropertyDetailsTemplate;
