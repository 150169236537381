import React, { useState } from "react"
import Slider from "react-slick";
import { Container, Row, Col, Form } from "react-bootstrap"
import NoImage from "../../../images/no-image.jpg"
import { useStaticQuery, graphql, Link } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ModalTiny from "react-bootstrap/Modal"
import BookViewForm from "../../forms/book-a-viewing-form"
import _ from "lodash";
import { inViewOptions1, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import "./Gallery.scss"

const Gallery = (props) => {

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props.data.images
    var propertyLightImages = propertyImages && propertyImages.slice(1, 30).map(img => img.srcUrl);
    // Property details images lightbox
    return (
        <InView  {...inViewOptions1}>
            {({ ref, inView }) => (
                <motion.section
                    className="property-gallery"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                >

                    <Container>
                        <Row>
                            <Col >
                                <div className="title">
                                    <h2>Media Gallery</h2>
                                </div>
                                <div className={'gallery-img'}>
                                    <Row>
                                        {
                                            props.data.images.slice(1, 30).map((img, index) => {

                                                let processedImages = JSON.stringify({});
                                                if (props.data?.imagetransforms?.images_Transforms) {
                                                    processedImages = props.data.imagetransforms.images_Transforms;
                                                }
                                                return (
                                                    <Col lg={4} md={6} className="img-zoom image">
                                                        <motion.div key={index} custom={index + 1} variants={fadeInFromTop} onClick={(e) => openPropertyImage(e, 0)}>
                                                            {img.url ?
                                                            <ImageTransform
                                                                imagesources={img.url}
                                                                renderer="srcSet"
                                                                imagename="new-developments.images.details1"
                                                                attr={{ alt: '', class: '' }}
                                                                imagetransformresult={processedImages}
                                                                id={props.data.id}
                                                                ggfxName="FEED"
                                                            /> :
                                                            <img src={img.srcUrl} className="image-fit-cover" alt="" onClick={(e) => openPropertyImage(e, 0)} /> }
                                                        </motion.div>
                                                    </Col>

                                                )
                                            })
                                        }

                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                    {/* Property image lightbox */}
                    {
                        isOpen && (
                            <Lightbox
                                mainSrc={propertyLightImages[photoIndex]}
                                nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                                prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                                }
                            />
                        )
                    }
                    {/* Property image lightbox */}
                </motion.section>
            )}
        </InView>

    )
}
export default Gallery;