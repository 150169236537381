import React, { useState } from "react"
import Slider from "react-slick";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col, Form } from "react-bootstrap"
import Img01 from "../../../images/Property/property01.png"
import Img001 from "../../../images/Property/property001.png"
import Img002 from "../../../images/Property/property002.png"
import ImgDesk01 from "../../../images/Property/desk-property01.png"
import ImgDesk02 from "../../../images/Property/desk-property002.png"
import ImgDesk03 from "../../../images/Property/desk-property003.png"
import { useStaticQuery, graphql, Link } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ModalTiny from "react-bootstrap/Modal"
import BookViewForm from "../../forms/book-a-viewing-form"
import _ from "lodash";
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import "./Property.scss"

const PROPERTY_AVAIL = gql`
  query GetPropertyAvail ($id: Int!){  
    properties(where:{primary_development: $id, publish:true}) {
        primary_development
        display_address
        bedroom
        price
        search_type
        slug    
        crm_id
        brand_id
        images
    }
  }
`;

const PropertyAvailability = (props) => {


    const [allproperties, setAllProperties] = useState([]);

    const { loading, error, data } = useQuery(PROPERTY_AVAIL, {
        variables: { id: props.id }, context: { clientName: "feed" }
    });
    // 
    if (!loading && _.isEmpty(allproperties) && data) {
        if (data.properties && data.properties.length > 0) {
            setAllProperties(data?.properties)
        }
    }

    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                </div>
            </div>
        </section>
    );


    var availabilities = data?.properties


    const brandURL = (brand_id) => {

        if (brand_id === "pt") {
            return process.env.GATSBY_BRAND_SITE_PT;
        } else if (brand_id === "cw") {
            return process.env.GATSBY_BRAND_SITE_CW;
        } else if (brand_id === "da") {
            return process.env.GATSBY_BRAND_SITE_DA;
        } else if (brand_id === "wk") {
            return process.env.GATSBY_BRAND_SITE_WK;
        } else {
            return '';
        }
    }


    const settings = {
        speed: 800,
        infinite: availabilities?.length > 3 ? true : false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 3020,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,

                }
            }
        ]
    };

    return (
        <React.Fragment>
            {
                availabilities && availabilities.length > 0 &&


                <InView  {...inViewOptions}>
                    {({ ref, inView }) => (
                        <motion.section
                            className="property property-availability-list"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={contentItemStagger}

                        >


                            <Container>
                                <Row>
                                    <Col >
                                        <div className="title">
                                            <h2>Property Availability</h2>
                                        </div>

                                        <div>
                                            <Slider {...settings} >
                                                {availabilities?.length > 0 && availabilities.slice(0, 8).map((item, k) => {
                                                    var url = ''
                                                    if (item.search_type === "sales") {
                                                        url = '/property-for-sale/' + item.slug + '-' + item.crm_id
                                                    }
                                                    else if (item.search_type === "lettings") {
                                                        url = '/property-to-rent/' + item.slug + '-' + item.crm_id
                                                    }

                                                    return (
                                                        <div className="property-card-list " key={k}>
                                                            <div className="properties-wrapper ">
                                                                <div className="property-img img-zoom">
                                                                    <Link to={`${url}`} className="img-link" target="_blank">
                                                                        <picture>
                                                                            {item.images && item.images.length > 0 ?
                                                                                <LazyLoadImage
                                                                                    wrapperProps={{ style: { display: "block" } }}
                                                                                    alt={'property Image'}
                                                                                    src={item.images[0]?.url} /> :
                                                                                <img src={Img01} alt="img" />
                                                                            }
                                                                        </picture>
                                                                    </Link>
                                                                </div>

                                                                <div className="properties-info">
                                                                    <h2 className="heading-info d-flex justify-content-between" >
                                                                        <Link to={`${url}`} className="img-link" target="_blank">{item.display_address}</Link>
                                                                    </h2>
                                                                    <span className="info-text">{props.title}{item.search_type}</span>
                                                                    {item.bedroom && item.bedroom > 1 ?
                                                                        <span className="info-beds"><i className="icon-house-light-blue"></i>{item.bedroom} Bedrooms</span>
                                                                        : item.bedroom && item.bedroom == 1 ?
                                                                            <span className="info-beds"><i className="icon-house-light-blue"></i>{item.bedroom} Bedroom</span>
                                                                            : <p className="empty-mb">&nbsp;</p>
                                                                    }
                                                                    <span className="info-price">
                                                                        <i className="icon-currency"></i>
                                                                        Starting from £{item.price.toLocaleString()}
                                                                    </span>
                                                                    <div className="properties-icons">
                                                                        <Link to={`${url}`} target="_blank">
                                                                            <span className="text">
                                                                                <i className="icon-sofa"></i>
                                                                                <span className="sm-text">View Details</span>
                                                                            </span>
                                                                        </Link>

                                                                        {/* <span className="text" onClick={openSendfrndformModal}>
                                                                    <i className="icon-sofa"></i>
                                                                    <span className="sm-envelope">Regiter yout intrest</span>
                                                                </span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item.images.length > 0 &&
                                                                <Link to={`${url}`} target="_blank">
                                                                    <div className="btn-group-wrap">
                                                                        <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.images.length}</span></button>

                                                                        {/* <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
                                                                        <button className="btn-flor"><i className="icon-flor"></i> </button> */}
                                                                    </div>
                                                                </Link>
                                                            }
                                                        </div>
                                                    )
                                                })}

                                            </Slider>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </motion.section>
                    )}
                </InView>

            }
        </React.Fragment >
    )
}
export default PropertyAvailability;